<template>
  <div>
    <no-access-info-card v-if="showNoAccessInfoCard" />
    <div v-else>
      <div v-if="course">
        <webinar-card
          v-for="item in course.data"
          :id="item.id"
          :key="item.id"
          :course="item.attributes"
          :can-show-image="showImages"
          @onImgLoad="loadedImages+= 1"
          @haveImage="imagesCount+= 1"
        />
        <div class="d-flex align-items-center justify-content-center">
          <b-pagination
            v-if="course.meta.pagination.total > course.meta.pagination.per_page"
            v-model="currentPage"
            :per-page="course.meta.pagination.per_page"
            :total-rows="course.meta.pagination.total"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </div>
      <div
        v-if="!course || !course.data.length"
        class="d-flex align-items-center justify-content-center"
      >
        <b-img
          src="@/assets/images/pages/course/empty-list.svg"
          alt="Buypass"
          class="d-inline-block mt-5"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { BPagination, BImg } from 'bootstrap-vue'
import WebinarCard from '@/components/course/WebinarCard.vue'
import NoAccessInfoCard from '@/components/NoAccessInfoCard.vue'

export default {
  components: {
    BPagination,
    BImg,
    WebinarCard,
    NoAccessInfoCard,
  },
  data() {
    return {
      loadedImages: 0,
    }
  },
  computed: {
    showImages() {
      return this.loadedImages >= this.course.data.filter(el => el.attributes.image).length
    },
    userPermissions() {
      return this.$store.getters['auth/permissions']
    },
    currentPage: {
      get() {
        return this.$store.getters['course/pageNumber']
      },
      set(pageNumber) {
        this.loadedImages = 0
        this.$store.commit('course/setPageNumber', pageNumber)
      },
    },
    course() {
      return this.$store.getters['course/myCourse']
    },
    user() {
      return this.$store.getters['auth/user']
    },
    representACompanyAdmin() {
      return 'id' in this.user.active_business_profile && this.user.active_business_profile.role === 'admin'
    },
    showNoAccessInfoCard() {
      if (this.representACompanyAdmin) return false
      if (!this.checkPermission('COURSE_READ', this.userPermissions)) return true
      return false
    },
  },
}
</script>
